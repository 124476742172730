








import { Component, Vue, Prop } from "vue-property-decorator";
import ProjectValuationReport from "@/components/project-valuation-report/ProjectValuationReport.vue";
import { ProjectValuationSnapshot as ProjectValuationSnapshotDTO } from "@/entity/project-valuation/ProjectValuationSnapshot";
import { defaultUserInput } from "@/store/projectValuationReport/state";
import ProjectValuationReportService from "@/services/projectValuation/ProjectValuationReportService";
import { ProjectValuationReportUserInput } from "@/store/projectValuationReport/types";
import { Mutation } from "vuex-class";

@Component({
  components: {
    ProjectValuationReport,
  },
})
export default class ProjectValuationReportView extends Vue {
  @Prop({ type: String, required: true }) token!: string;
  @Mutation("setUserInputData", { namespace: "projectValuationReport" })
  setUserInputData!: (data: ProjectValuationReportUserInput) => void;

  isFetchingSnapshot = false;
  snapshot: null | ProjectValuationSnapshotDTO = null;

  get userInput() {
    if (this.snapshot?.userInput === null) {
      return defaultUserInput();
    }

    return this.snapshot?.userInput;
  }

  async fetchSnapshot(token: string): Promise<void> {
    try {
      this.isFetchingSnapshot = true;
      this.snapshot = await ProjectValuationReportService.getWithToken(token);
      if (this.snapshot.userInput !== null) {
        this.setUserInputData(this.snapshot.userInput);
      }
    } catch (e) {
      this.$snackbarError("Unable to fetch snapshot");
    } finally {
      this.isFetchingSnapshot = false;
    }
  }

  hyphenate() {
    const Hyphenopoly = (window as any).Hyphenopoly;
    Hyphenopoly.hyphenators["de"].then(function (hyphenate: any) {
      const nl = document.querySelectorAll(".hyphenation");
      nl.forEach((el) => {
        hyphenate(el, ".hyphenation");
      });
    });
  }

  async created() {
    await this.fetchSnapshot(this.token);
    this.hyphenate();
  }
}
